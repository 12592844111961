import React, { Component } from 'react';
import logo192 from './assets/logo192.png'

export default class Home extends Component {

    render() {
        return (
            <div>
                <img src={logo192} alt="Logo Gold Car" /> <br />
                Gold Car <br />
                (31) 3398-3043 <br />
                Em breve um novo site pra você. <br />
                <a href="mailto:contato@goldcarmg.com.br">contato@goldcarmg.com.br</a><br />
                Rua Eufrasia Augusta de Jesus, 53 - Santa Helena <br />
                Contagem - MG
            </div >)
    };
}